import React from 'react';

import classNames from 'classnames';
import { Swiper } from 'swiper/types';

import ArrowLeft from '@/public/images/arrow-left.svg';
import ArrowRight from '@/public/images/arrow-right.svg';

import classes from './navigation-button.module.scss';

interface NavigationButtonProps {
  direction: 'next' | 'prev';
  swiper: Swiper;
  className?: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
  const { direction, swiper, className } = props;

  const handleClick = () => {
    if (direction === 'next') {
      swiper.slideNext();
    }
    if (direction === 'prev') {
      swiper.slidePrev();
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      aria-label={direction}
      className={classNames(classes.btn, className)}
    >
      {direction === 'next' && <ArrowRight />}
      {direction === 'prev' && <ArrowLeft />}
    </button>
  );
};

export default NavigationButton;
