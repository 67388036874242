'use client';

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTheme } from '@mui/material';
import Link from 'next/link';
import 'swiper/css';
import { Swiper as ISwiper } from 'swiper/types';

import Image from '@/app/_components/image';
import { JEWELRY } from '@/config/routes';
import { ProductCategory } from '@/types/product';

import NavigationWithPagination from '../swiper/navigation-with-pagination';

import classes from './index.module.scss';

interface JewelryCategoriesProps {
  list: ProductCategory[];
}

const JewelryCategories: React.FC<JewelryCategoriesProps> = (props) => {
  const { list } = props;
  const [swiper, setSwiper] = useState<ISwiper | null>(null);

  const theme = useTheme();

  return (
    <section className={classes.wrap}>
      <div className={classes.grid}>
        <div className={classes.titleWrap}>
          <div className={classes.caption}>
            Shop our collection of stunning pieces that take every outfit to the
            next level.
          </div>
          <h2>shop your Jewelry</h2>
        </div>
        <div className={classes.sliderWrap}>
          <Swiper
            spaceBetween="1"
            slidesPerView="auto"
            centeredSlides
            loop
            onSwiper={setSwiper}
            breakpoints={{
              [theme.breakpoints.values.md]: {
                centeredSlides: false,
              },
            }}
          >
            {list.map((slide) => (
              <SwiperSlide className={classes.slide} key={slide.id}>
                <Link
                  className={classes.contentWrap}
                  href={`${JEWELRY.fullPath}/${slide.slug || slide.id}`}
                >
                  <div className={classes.imgWrap}>
                    {slide.imageUrl && (
                      <Image alt="" src={slide.imageUrl} fill />
                    )}
                  </div>
                  <div className={classes.name}>{slide.title}</div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={classes.controls}>
          {swiper && <NavigationWithPagination swiper={swiper} />}
        </div>
      </div>
    </section>
  );
};

export default JewelryCategories;
