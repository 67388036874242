'use client';

import React, { useState } from 'react';

import { Zoom } from '@mui/material';
import classNames from 'classnames';

import Image from '../image';

import classes from './index.module.scss';

interface MetalListProps {
  currantMetal?: number;
  metals: {
    imageUrl: string | null;
    title: string;
    id: number;
  }[];
}

const MetalList: React.FC<MetalListProps> = (props) => {
  const { metals, currantMetal } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleList = metals.length > 3;

  return (
    <div className={classes.metalsWrap}>
      <div className={classes.metals}>
        {metals.map((metal, index) => (
          <Zoom unmountOnExit in={index < 3 || isOpen} key={metal.id}>
            <div className={classes.metalWrap}>
              <div
                className={classNames(classes.metal, {
                  [classes.active]: currantMetal === metal.id,
                })}
                title={metal.title}
              >
                {metal.imageUrl && (
                  <Image
                    fallback
                    src={metal.imageUrl}
                    width={50}
                    height={50}
                    alt=""
                  />
                )}
              </div>
            </div>
          </Zoom>
        ))}
      </div>
      {toggleList && !isOpen && (
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className={classes.moreBtn}
        >
          more
        </button>
      )}
    </div>
  );
};

export default MetalList;
