'use client';

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTheme } from '@mui/material';
import classNames from 'classnames';
import 'swiper/css';
import { Swiper as ISwiper } from 'swiper/types';

import NavigationWithPagination from '../swiper/navigation-with-pagination';

import classes from './index.module.scss';

interface SliderWithTitleProps {
  title: string | React.ReactNode;
  list: { id: string | number; content: React.ReactNode }[];
  classes?: {
    titleWrap?: string;
    slide?: string;
    slider?: string;
  };
  paginationColor?: 'primary' | 'inherit';
  spaceBetween?: number | string;
  loop?: boolean;
}

const SliderWithTitle: React.FC<SliderWithTitleProps> = (props) => {
  const {
    list,
    title,
    classes: propsClasses,
    paginationColor,
    spaceBetween,
    loop = true,
  } = props;
  const [swiper, setSwiper] = useState<ISwiper | null>(null);

  const theme = useTheme();

  return (
    <div className={classes.wrap}>
      <div className={classNames(classes.titleWrap, propsClasses?.titleWrap)}>
        <div>{typeof title === 'string' ? <h2>{title}</h2> : title}</div>
      </div>
      <div className={classNames(classes.slider, propsClasses?.slider)}>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView="auto"
          spaceBetween={spaceBetween}
          loop={loop}
          centeredSlides
          breakpoints={{
            [theme.breakpoints.values.md]: {
              centeredSlides: false,
            },
          }}
        >
          {list.map((item) => (
            <SwiperSlide key={item.id} className={propsClasses?.slide}>
              {item.content}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={classes.controls}>
        {swiper && list.length > 0 && (
          <NavigationWithPagination swiper={swiper} color={paginationColor} />
        )}
      </div>
    </div>
  );
};

export default SliderWithTitle;
