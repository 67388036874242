import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Swiper } from 'swiper/types';

import NavigationButton from './navigation-button';

import classes from './navigation-with-pagination.module.scss';

interface NavigationWithPaginationProps {
  swiper: Swiper;
  classes?: {
    wrap?: string;
  };
  color?: 'primary' | 'inherit';
}

const NavigationWithPagination: React.FC<NavigationWithPaginationProps> = (
  props,
) => {
  const { swiper, classes: styles, color = 'inherit' } = props;

  const [curretSlide, setCurretSlide] = useState(1);

  useEffect(() => {
    swiper.on('slideChange', (slider) => {
      setCurretSlide(slider.realIndex + 1);
    });
  }, []);

  return (
    <div className={classNames(classes.wrap, styles?.wrap, classes[color])}>
      <NavigationButton
        direction="prev"
        swiper={swiper}
        className={classNames(classes.wrap, classes[color])}
      />
      <div className={classes.pagination}>
        <div className={classes.value}>{curretSlide}</div>
        <div className={classes.separator}>/</div>
        <div className={classes.length}>{(swiper.slides || []).length}</div>
      </div>
      <NavigationButton
        direction="next"
        swiper={swiper}
        className={classNames(classes.wrap, classes[color])}
      />
    </div>
  );
};

export default NavigationWithPagination;
