'use client';

import React from 'react';

import { IconButton } from '@mui/material';

import useDiamondCompareList from '@/hooks/use-diamond-compare-list';
import DiamondFilledIcon from '@/public/icons/diamond-filled.svg';
import DiamondIcon from '@/public/icons/diamond.svg';

interface Props {
  id: number;
}

const CompareBtn: React.FC<Props> = (props) => {
  const { id } = props;
  const { data: list, toggle } = useDiamondCompareList();

  const handleCompare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggle(id);
  };

  const isOnList = list.find((i) => i.id === id);

  return (
    <IconButton onClick={handleCompare}>
      {isOnList ? <DiamondFilledIcon /> : <DiamondIcon />}
    </IconButton>
  );
};

export default CompareBtn;
