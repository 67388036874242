import {
  getAppointmentPageData,
  getCollectionsPageData,
  getGiftGuidePageData,
  getHelpPageData,
  getHomePageData,
  getTermsPageData,
} from '@/api/pages';

const keys = {
  homePageData: 'home/page/data',
  helpPageData: 'help/page/data',
  collectionsPageData: 'collections/page/data',
  giftGuidePageData: 'gift-guide/page/data',
  termsPageData: 'terms/page/data',
  appointmentPageData: 'appointment/page/data',
};

export const homePageQueryCreator = () => ({
  queryKey: [keys.homePageData],
  queryFn: () => getHomePageData(),
});

export const helpPageQueryCreator = () => ({
  queryKey: [keys.helpPageData],
  queryFn: () => getHelpPageData(),
});

export const collectionsPageQueryCreator = () => ({
  queryKey: [keys.collectionsPageData],
  queryFn: () => getCollectionsPageData(),
});

export const giftGuideQueryCreator = () => ({
  queryKey: [keys.giftGuidePageData],
  queryFn: () => getGiftGuidePageData(),
});

export const termsPageQueryCreator = () => ({
  queryKey: [keys.termsPageData],
  queryFn: () => getTermsPageData(),
});

export const appointmentPageQueryCreator = () => ({
  queryKey: [keys.appointmentPageData],
  queryFn: () => getAppointmentPageData(),
});
