import api from './api';

export const subscribe = (email: string) =>
  api({
    url: '/subscribers',
    method: 'POST',
    data: { email },
    transformData: { formData: true },
  });

export const unsubscribe = (email: string) =>
  api({
    url: '/subscribers',
    method: 'POST',
    data: { email },
    transformData: { formData: true },
  });
