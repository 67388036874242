'use client';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTheme } from '@mui/material';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import Image from '@/app/_components/image';
import { Designer } from '@/types/designers';

import classes from './creeping-line.module.scss';

type Props = {
  list: Designer[];
};

const CreepingLine: React.FC<Props> = (props) => {
  const { list } = props;
  const theme = useTheme();

  const numVisibleSlides = list.length;

  return (
    <section className={classes.wrap}>
      <div className={classes.sliderWrapper}>
        <Swiper
          loop
          centeredSlides={numVisibleSlides > 5}
          centerInsufficientSlides={numVisibleSlides <= 5}
          modules={numVisibleSlides > 5 ? [Autoplay] : undefined}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={5000}
          breakpoints={{
            [theme.breakpoints.values.sm]: {
              slidesPerView: 2,
            },
            [theme.breakpoints.values.md]: {
              slidesPerView: 3,
            },
            [theme.breakpoints.values.lg]: {
              slidesPerView: 4,
            },
          }}
          className={classes.slider}
        >
          {list.map((item) => (
            <SwiperSlide key={item.id} className={classes.slide}>
              <div className={classes.img}>
                <Image src={item.imageUrl || ''} alt={item.name} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default CreepingLine;
