'use client';

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  IconButton,
  TextField as MuiTextField,
  outlinedInputClasses,
  styled,
} from '@mui/material';
import * as yup from 'yup';

import { subscribe } from '@/api/subscription';
import Image from '@/app/_components/image';
import ArrowRight from '@/public/images/arrow-right.svg';
import logo from '@/public/images/logo.png';
import { homePageQueryCreator } from '@/store/pages';

import classes from './index.module.scss';

const TextField = styled(MuiTextField)(({ theme }) => ({
  fontWeight: 500,
  [`.${outlinedInputClasses.input}`]: {
    fontWeight: 500,
    height: 36,
    paddingRight: 108,
    '&::placeholder': {
      opacity: 1,
    },
  },
  [`.${outlinedInputClasses.root}`]: {
    [`&:not(.${outlinedInputClasses.error})`]: {
      '&:hover': {
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.accent.main,
        },
      },
      [`&.${outlinedInputClasses.focused}`]: {
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.accent.main,
        },
      },
      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.accent.main,
      },
    },
  },
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

type Fields = yup.InferType<typeof schema>;

const SubscriptionBanner: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Fields>({ resolver: yupResolver(schema) });
  const { data: homePageData } = useQuery(homePageQueryCreator());

  const fields = {
    email: register('email'),
  };

  const onSubmit = async (data: Fields) => {
    setSending(true);
    try {
      await subscribe(data.email);

      setIsSuccess(true);
    } catch (e) {
      setError('email', { message: (e as { message: string }).message });
    }
    setSending(false);
  };

  return (
    <section className={classes.wrap}>
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <div>
              <div className={classes.logoWrap}>
                <Image src={logo} alt="logo" />
              </div>
              <h2>{homePageData?.data.options?.newsletters.title}</h2>
              <h3>{homePageData?.data.options?.newsletters.subTitle}</h3>
              <p className={classes.caption}>
                {homePageData?.data.options?.newsletters.description}
              </p>
              <div className={classes.form}>
                {isSuccess ? (
                  <h4>
                    Thank you! You have successfully subscribed for our
                    newsletters
                  </h4>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      fullWidth
                      color="accent"
                      ref={fields.email.ref}
                      name={fields.email.name}
                      onChange={fields.email.onChange}
                      onBlur={fields.email.onBlur}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      placeholder="ENTER YOUR EMAIL"
                    />
                    <div className={classes.btnWrap}>
                      <IconButton
                        type="submit"
                        disabled={sending}
                        aria-label="submit"
                      >
                        <ArrowRight />
                      </IconButton>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div className={classes.bg}>
            {homePageData?.data.options?.newsletters.mediaUrl && (
              <Image
                src={homePageData.data.options.newsletters.mediaUrl}
                alt="background"
                fill
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SubscriptionBanner;
