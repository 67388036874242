'use client';

import React from 'react';

import { IconButton } from '@mui/material';

import useWishlist from '@/hooks/use-wishlist';
import HeartFilledIcon from '@/public/icons/heart-filled.svg';
import HeartIcon from '@/public/icons/heart.svg';
import { ProductType } from '@/types/product';

type Props = {
  id: number;
  type: ProductType;
};

const WishlistBtn: React.FC<Props> = (props) => {
  const { id, type } = props;
  const { diamondsList, variantsList, toggle } = useWishlist();

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggle({ id, type });
  };

  const list = type === 'diamond' ? diamondsList : variantsList;

  const isOnList = list.find((i) => i.id === id);

  return (
    <IconButton onClick={onClick}>
      {isOnList ? <HeartFilledIcon /> : <HeartIcon />}
    </IconButton>
  );
};

export default WishlistBtn;
