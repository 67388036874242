'use client';

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTheme } from '@mui/material';
import Link from 'next/link';
import 'swiper/css';
import { Swiper as ISwiper } from 'swiper/types';

import Image from '@/app/_components/image';
import Shape from '@/app/_components/shape';
import NavigationButton from '@/app/_components/swiper/navigation-button';
import { DIAMONDS } from '@/config/routes';
import { DiamondAttributeCode, Attribute } from '@/types/diamond';

import classes from './index.module.scss';

interface Props {
  list: Attribute<DiamondAttributeCode.shape>[];
}

const List: React.FC<Props> = (props) => {
  const { list } = props;
  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const theme = useTheme();

  return (
    <div className={classes.sliderWrap}>
      {swiper && (
        <NavigationButton
          direction="prev"
          swiper={swiper}
          className={classes.prev}
        />
      )}
      <div className={classes.slider}>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          loop
          centerInsufficientSlides
          onSwiper={setSwiper}
          breakpoints={{
            [theme.breakpoints.values.lg]: {
              slidesPerView: 4,
              centeredSlides: false,
            },
            [theme.breakpoints.values.xl]: {
              slidesPerView: 5,
            },
            [theme.breakpoints.values.xxl]: {
              slidesPerView: 7,
            },
          }}
        >
          {list.map((item) => (
            <SwiperSlide key={item.id}>
              <Link
                href={`${DIAMONDS.fullPath}?shape=${item.id}`}
                className={classes.slide}
              >
                <div className={classes.imgWrap}>
                  <Shape src={item.schematicImageUrl || ''} />
                  {item.imageUrl && (
                    <Image alt="diamond" src={item.imageUrl} fill />
                  )}
                </div>
                <span className={classes.name}>{item.title || ''}</span>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {swiper && (
        <NavigationButton
          direction="next"
          swiper={swiper}
          className={classes.next}
        />
      )}
    </div>
  );
};

export default List;
