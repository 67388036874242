import {
  AppointmentPageData,
  AboutUsPageData,
  CollectionsPageData,
  CouplesPageData,
  DesignersPageData,
  DiamondSearchPageData,
  DiamondsPageData,
  EtiquettePageData,
  GiftGuidePageData,
  HelpPageData,
  HomePageData,
  JewelryPageData,
  LifetimeWarrantyPageData,
  ReturnPolicyPageData,
  ShippingPolicyPageData,
  TermsPageData,
} from '@/types/pages';

import api, { Response } from './api';

export const getHomePageData = () =>
  api<Response<HomePageData>>({
    url: '/pages/home',
  });

export const getHelpPageData = () =>
  api<Response<HelpPageData>>({
    url: '/pages/help',
  });

export const getCollectionsPageData = () =>
  api<Response<CollectionsPageData>>({
    url: '/pages/collections',
  });

export const getDiamondsPageData = () =>
  api<Response<DiamondsPageData>>({
    url: '/pages/diamonds',
  });

export const getDiamondSearchPageData = () =>
  api<Response<DiamondSearchPageData>>({
    url: '/pages/diamond-search',
  });

export const getDesignersPageData = () =>
  api<Response<DesignersPageData>>({
    url: '/pages/designers',
  });

export const getJewelryPageData = () =>
  api<Response<JewelryPageData>>({
    url: '/pages/jewelry',
  });

export const getGiftGuidePageData = () =>
  api<Response<GiftGuidePageData>>({
    url: '/pages/gift-guide',
  });

export const getTermsPageData = () =>
  api<Response<TermsPageData>>({
    url: '/pages/terms',
  });

export const getEtiquettePageData = () =>
  api<Response<EtiquettePageData>>({
    url: '/pages/etiquette',
  });

export const getLifetimeWarrantyPageData = () =>
  api<Response<LifetimeWarrantyPageData>>({
    url: '/pages/lifetime-warranty',
  });

export const getShippingPolicyPageData = () =>
  api<Response<ShippingPolicyPageData>>({
    url: '/pages/shipping-policy',
  });

export const getReturnPolicyPageData = () =>
  api<Response<ReturnPolicyPageData>>({
    url: '/pages/return-policy',
  });

export const getCouplesPageData = () =>
  api<Response<CouplesPageData>>({
    url: '/pages/couples',
  });

export const getAppointmentPageData = () =>
  api<Response<AppointmentPageData>>({
    url: '/pages/appointment',
  });

export const getAboutUsPageData = () =>
  api<Response<AboutUsPageData>>({
    url: '/pages/about-us',
  });
